import { render, staticRenderFns } from "./GeneralForm.vue?vue&type=template&id=18c683f9"
import script from "./GeneralForm.vue?vue&type=script&lang=ts"
export * from "./GeneralForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports